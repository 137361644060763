<template>
  <div
    class="quick-switch__container"
    @mouseenter="handleMouseEnter">
    <div
      :class="['quick-switch__container-content', {
        'quick-switch__container-content--clickable': isEnvClickable,
      }]"
      @click="handleContainerClick">
      <CyStackAvatar
        v-if="isComponentScope"
        :stack="currentEnvComponent.service_catalog"
        class="current-focus__avatar"
        size="32px"/>
      <CyAvatar
        v-else
        :item="{ canonical: currentEnv.canonical, icon: 'mdi-layers-outline', color: currentEnv.color }"
        class="current-focus__avatar"
        icon-size="24"/>
      <span class="current-focus__text">
        {{ isComponentScope ? currentEnvComponent.name : currentEnv.name }}
      </span>
    </div>
    <CyMenu
      v-model="isMenuOpen"
      bottom
      :close-on-content-click="false"
      content-class="quick-switch-menu"
      min-width="528"
      offset-y
      origin="top right"
      transition="slide-y-transition"
      @input="handleMenuToggle">
      <template #activator="{ on, value: isOpen }">
        <v-icon
          :class="['quick-switch__activator-icon', {
            'quick-switch__activator-icon--active': isOpen,
          }]"
          size="20"
          v-on="on">
          unfold_more
        </v-icon>
      </template>
      <div class="d-flex">
        <section
          :class="['quick-switch-menu__section', 'quick-switch-menu__section--environment', {
            'quick-switch-menu__section--focused': focusedSection === 'environment',
          }]"
          aria-label="Environment menu"
          @mouseover="focusedSection = 'environment'">
          <v-text-field
            v-model="envSearchQuery"
            :autofocus="scope === 'environment'"
            class="search-field"
            hide-details
            height="36px"
            :placeholder="$t('environment.find')">
            <template #prepend-inner>
              <v-icon
                class="search-field__icon"
                size="20px">
                search
              </v-icon>
            </template>
          </v-text-field>
          <v-divider class="mx-0"/>
          <v-list
            role="list"
            class="env-list">
            <v-list-item
              v-for="env in filteredEnvs"
              :key="env.canonical"
              :class="['env-list-item', { 'env-list-item--focused': env.canonical === focusedEnv }]"
              role="listitem"
              :aria-label="env.canonical"
              @click="selectEnv(env.canonical)"
              @mouseover="setFocusedEnv(env.canonical)">
              <CyAvatar
                :item="{ canonical: env.canonical, icon: 'mdi-layers-outline', color: env.color }"
                class="env-list-item__avatar"
                sm/>
              <v-list-item-content class="env-list-item__name">
                {{ env.name }}
              </v-list-item-content>
              <v-icon
                v-if="env.canonical === currentEnv.canonical"
                color="primary"
                size="16">
                check
              </v-icon>
            </v-list-item>
            <v-list-item
              class="env-list__action"
              @click="handleAddEnvironment">
              <v-icon
                color="primary"
                class="mr-2">
                add
              </v-icon>
              <v-list-item-content>
                {{ `${$t('environment.add')}...` }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </section>
        <section
          :class="[ 'quick-switch-menu__section', 'quick-switch-menu__section--component', {
            'quick-switch-menu__section--focused': focusedSection === 'envComponent',
          }]"
          aria-label="Component menu"
          @mouseover="focusedSection = 'envComponent'">
          <v-text-field
            v-model="componentSearchQuery"
            :autofocus="scope === 'envComponent'"
            class="search-field"
            hide-details
            height="36px"
            :placeholder="$t('environment.component.find')">
            <template #prepend-inner>
              <v-icon
                class="search-field__icon"
                size="20px">
                search
              </v-icon>
            </template>
          </v-text-field>
          <v-divider class="mx-0"/>
          <v-list
            role="list"
            class="component-list">
            <v-list-item
              v-for="component in filteredComponents"
              :key="component.canonical"
              class="component-list-item"
              role="listitem"
              :aria-label="component.name"
              @click="selectComponent(component.canonical)">
              <CyStackAvatar
                :stack="component.service_catalog"
                class="component-list-item__avatar"
                size="24px"/>
              <v-list-item-content class="component-list-item__name">
                {{ component.name }}
              </v-list-item-content>
              <v-icon
                v-if="shouldDisplayCheckIcon(component.canonical)"
                color="primary"
                size="16">
                check
              </v-icon>
            </v-list-item>
            <v-list-item
              class="component-list__action"
              @click="handleAddComponent">
              <v-icon
                color="primary"
                class="mr-2">
                add
              </v-icon>
              <v-list-item-content>
                {{ `${$t('environment.component.add')}...` }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </section>
      </div>
    </CyMenu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CyStackAvatar from '@/components/CyStackAvatar.vue'

export default {
  name: 'CyEnvironmentComponentQuickSwitch',
  components: {
    CyStackAvatar,
  },
  props: {
    currentEnv: {
      type: Object,
      required: true,
    },
    currentEnvComponent: {
      type: Object,
      default: null,
    },
    envs: {
      type: Array,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
  },
  data: ({ scope }) => ({
    focusedEnv: null,
    focusedSection: scope,
    envSearchQuery: '',
    componentSearchQuery: '',
    isMenuOpen: false,
  }),
  computed: {
    isComponentScope () {
      return this.scope === 'envComponent'
    },
    filteredEnvs () {
      return this.envs.filter((env) => env.canonical.toLowerCase().includes(this.envSearchQuery.toLowerCase()))
    },
    filteredComponents () {
      const focusedEnv = this.envs.find((env) => env.canonical === this.focusedEnv)
      return focusedEnv
        ? (focusedEnv.components || [])
            .filter((component) => component.name.toLowerCase().includes(this.componentSearchQuery.toLowerCase()))
        : []
    },
    isEnvClickable () {
      return this.scope === 'environment' && this.$route.params.componentCanonical
    },
  },
  mounted () {
    this.focusedEnv = this.currentEnv.canonical
  },
  methods: {
    ...mapActions('organization/project', [
      'GET_PROJECT_ENVS',
    ]),
    selectEnv (envCanonical) {
      if (envCanonical === this.currentEnv.canonical) return
      this.$emit('update:currentEnv', _.find(this.envs, ['canonical', envCanonical]))
      this.$emit('select:environment', envCanonical)
    },
    selectComponent (componentCanonical) {
      if (
        componentCanonical === this.currentEnvComponent?.canonical &&
        this.focusedEnv === this.currentEnv.canonical
      ) return
      this.$emit('select:component', this.focusedEnv, componentCanonical)
    },
    setFocusedEnv (envCanonical) {
      this.focusedEnv = envCanonical
    },
    handleMouseEnter () {
      this.GET_PROJECT_ENVS()
    },
    handleMenuToggle (isOpen) {
      if (!isOpen) {
        this.focusedEnv = this.currentEnv.canonical
        this.envSearchQuery = ''
        this.componentSearchQuery = ''
      }
    },
    shouldDisplayCheckIcon (componentCanonical) {
      return this.currentEnvComponent &&
        componentCanonical === this.currentEnvComponent.canonical &&
        this.focusedEnv === this.currentEnv.canonical
    },
    handleAddEnvironment () {
      this.$emit('add:environment')
      this.closeMenu()
    },
    handleAddComponent () {
      this.$emit('add:component', this.focusedEnv)
      this.closeMenu()
    },
    closeMenu () {
      this.isMenuOpen = false
    },
    handleContainerClick () {
      if (this.isEnvClickable) {
        this.$emit('select:environment', this.currentEnv.canonical)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.quick-switch {
  &__container {
    display: flex;
    align-items: center;
  }

  &__container-content {
    display: flex;
    align-items: center;
    margin-inline: -4px;
    padding: 0 4px 0 2px;
    border-radius: 8px;

    .current-focus {
      &__avatar {
        &.cy-avatar {
          width: 24px;
          min-width: auto;
          height: 24px;
          margin-right: 16px;
          padding-left: 8px;

          ::v-deep .v-icon {
            border-radius: 8px;
          }
        }

        &.cy-stack-avatar {
          margin-right: 6px;

          ::v-deep img {
            border-radius: 8px;
          }
        }
      }

      &__text {
        @extend %ellipsis;

        max-width: 300px;
        margin-right: 4px;
      }
    }

    &--clickable {
      cursor: pointer;
    }
  }

  &__activator-icon {
    padding: 8px 0;
    border-radius: 8px;
    color: cy-get-color("primary", "light-3");
    cursor: pointer;

    &:hover,
    &--active {
      background-color: cy-get-color("primary", "light-5");
    }

    ::v-deep &.v-icon:focus::after {
      opacity: 0;
    }
  }

  &__container-content:hover,
  &__container-content:focus-visible {
    background-color: cy-get-color("primary", "light-5");
  }

  &-menu {
    &__section {
      min-width: 50%;
      max-width: 50%;
      min-height: 390px;
      max-height: 390px;
      padding-top: 1px;

      &--focused {
        background-color: #F8FAFC;

        .env,
        .component {
          &-list {
            background-color: #F8FAFC;
          }
        }
      }

      &--environment {
        border-right: 1px solid cy-get-color("primary", "light-5");
      }

      .search-field {
        height: 36px;
        margin: 8px;
        padding-top: 0;
        border-radius: 4px;
        background-color: cy-get-color('primary', 'light-5');

        ::v-deep .v-input {
          &__slot {
            &::before {
              display: none;
            }

            &::after {
              display: none;
            }
          }
        }

        ::v-deep input::placeholder {
          color: cy-get-color('primary', 'light-3');
          font-size: $font-size-default;
        }

        ::v-deep input {
          color: cy-get-color('primary', 'light-2');
        }

        &__icon {
          margin-top: 4px;
          margin-left: 8px;
          color: cy-get-color('primary', 'light-3');
        }
      }

      .env-list,
      .component-list {
        &.v-list {
          padding-top: 8px !important;
          padding-bottom: 8px !important;
        }

        &-item {
          min-height: 40px;
          max-height: 40px;
          padding-inline: 16px;

          &__avatar {
            margin-right: 8px;
          }

          &__name {
            @extend %ellipsis;

            display: inline-block;
            max-width: 250px;
            color: cy-get-color('primary');
          }

          ::v-deep .v-list-item {
            &__icon {
              margin: 0;
            }
          }

          &--focused {
            background-color: cy-get-color('primary', 'light-5');
          }
        }

        &__action {
          min-height: 40px;
          max-height: 40px;
          color: cy-get-color('primary') !important;
        }
      }
    }
  }
}
</style>
