var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quick-switch__container",on:{"mouseenter":_vm.handleMouseEnter}},[_c('div',{class:['quick-switch__container-content', {
      'quick-switch__container-content--clickable': _vm.isEnvClickable,
    }],on:{"click":_vm.handleContainerClick}},[(_vm.isComponentScope)?_c('CyStackAvatar',{staticClass:"current-focus__avatar",attrs:{"stack":_vm.currentEnvComponent.service_catalog,"size":"32px"}}):_c('CyAvatar',{staticClass:"current-focus__avatar",attrs:{"item":{ canonical: _vm.currentEnv.canonical, icon: 'mdi-layers-outline', color: _vm.currentEnv.color },"icon-size":"24"}}),_c('span',{staticClass:"current-focus__text"},[_vm._v(" "+_vm._s(_vm.isComponentScope ? _vm.currentEnvComponent.name : _vm.currentEnv.name)+" ")])],1),_c('CyMenu',{attrs:{"bottom":"","close-on-content-click":false,"content-class":"quick-switch-menu","min-width":"528","offset-y":"","origin":"top right","transition":"slide-y-transition"},on:{"input":_vm.handleMenuToggle},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var isOpen = ref.value;
return [_c('v-icon',_vm._g({class:['quick-switch__activator-icon', {
          'quick-switch__activator-icon--active': isOpen,
        }],attrs:{"size":"20"}},on),[_vm._v(" unfold_more ")])]}}]),model:{value:(_vm.isMenuOpen),callback:function ($$v) {_vm.isMenuOpen=$$v},expression:"isMenuOpen"}},[_c('div',{staticClass:"d-flex"},[_c('section',{class:['quick-switch-menu__section', 'quick-switch-menu__section--environment', {
          'quick-switch-menu__section--focused': _vm.focusedSection === 'environment',
        }],attrs:{"aria-label":"Environment menu"},on:{"mouseover":function($event){_vm.focusedSection = 'environment'}}},[_c('v-text-field',{staticClass:"search-field",attrs:{"autofocus":_vm.scope === 'environment',"hide-details":"","height":"36px","placeholder":_vm.$t('environment.find')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"search-field__icon",attrs:{"size":"20px"}},[_vm._v(" search ")])]},proxy:true}]),model:{value:(_vm.envSearchQuery),callback:function ($$v) {_vm.envSearchQuery=$$v},expression:"envSearchQuery"}}),_c('v-divider',{staticClass:"mx-0"}),_c('v-list',{staticClass:"env-list",attrs:{"role":"list"}},[_vm._l((_vm.filteredEnvs),function(env){return _c('v-list-item',{key:env.canonical,class:['env-list-item', { 'env-list-item--focused': env.canonical === _vm.focusedEnv }],attrs:{"role":"listitem","aria-label":env.canonical},on:{"click":function($event){return _vm.selectEnv(env.canonical)},"mouseover":function($event){return _vm.setFocusedEnv(env.canonical)}}},[_c('CyAvatar',{staticClass:"env-list-item__avatar",attrs:{"item":{ canonical: env.canonical, icon: 'mdi-layers-outline', color: env.color },"sm":""}}),_c('v-list-item-content',{staticClass:"env-list-item__name"},[_vm._v(" "+_vm._s(env.name)+" ")]),(env.canonical === _vm.currentEnv.canonical)?_c('v-icon',{attrs:{"color":"primary","size":"16"}},[_vm._v(" check ")]):_vm._e()],1)}),_c('v-list-item',{staticClass:"env-list__action",on:{"click":_vm.handleAddEnvironment}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" add ")]),_c('v-list-item-content',[_vm._v(" "+_vm._s(((_vm.$t('environment.add')) + "..."))+" ")])],1)],2)],1),_c('section',{class:[ 'quick-switch-menu__section', 'quick-switch-menu__section--component', {
          'quick-switch-menu__section--focused': _vm.focusedSection === 'envComponent',
        }],attrs:{"aria-label":"Component menu"},on:{"mouseover":function($event){_vm.focusedSection = 'envComponent'}}},[_c('v-text-field',{staticClass:"search-field",attrs:{"autofocus":_vm.scope === 'envComponent',"hide-details":"","height":"36px","placeholder":_vm.$t('environment.component.find')},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{staticClass:"search-field__icon",attrs:{"size":"20px"}},[_vm._v(" search ")])]},proxy:true}]),model:{value:(_vm.componentSearchQuery),callback:function ($$v) {_vm.componentSearchQuery=$$v},expression:"componentSearchQuery"}}),_c('v-divider',{staticClass:"mx-0"}),_c('v-list',{staticClass:"component-list",attrs:{"role":"list"}},[_vm._l((_vm.filteredComponents),function(component){return _c('v-list-item',{key:component.canonical,staticClass:"component-list-item",attrs:{"role":"listitem","aria-label":component.name},on:{"click":function($event){return _vm.selectComponent(component.canonical)}}},[_c('CyStackAvatar',{staticClass:"component-list-item__avatar",attrs:{"stack":component.service_catalog,"size":"24px"}}),_c('v-list-item-content',{staticClass:"component-list-item__name"},[_vm._v(" "+_vm._s(component.name)+" ")]),(_vm.shouldDisplayCheckIcon(component.canonical))?_c('v-icon',{attrs:{"color":"primary","size":"16"}},[_vm._v(" check ")]):_vm._e()],1)}),_c('v-list-item',{staticClass:"component-list__action",on:{"click":_vm.handleAddComponent}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" add ")]),_c('v-list-item-content',[_vm._v(" "+_vm._s(((_vm.$t('environment.component.add')) + "..."))+" ")])],1)],2)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }